import { useContext, Link, useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPhone,
  faComments,
} from "@fortawesome/free-solid-svg-icons";

import MainContext from "../Context";

import Logo from "../Images/logo.svg";

export default function Header() {
  const { device } = useContext(MainContext);

  function AfterScroll() {
    document.getElementById("header").className =
      "z-50 fixed table bg-lighterBlue lg:bg-blue w-full h-12 bg-opacity-100 duration-500 shadow";
    document.getElementById("name").className =
      "text-white table-cell align-middle text-left pl-8 text-4xl w-[250px] duration-500";
    document.getElementById("logo").className =
      "table-cell align-middle pl-0 w-[0px] h-[0px] opacity-0 duration-500";
    document.getElementById("logoInside").className =
      "h-[0px] w-[0px] opacity-0 duration-500";
    document.getElementById("contacts").className =
      "text-white table-cell align-middle text-right py-[4px] text-lg duration-500";
  }

  function BeforeScroll() {
    document.getElementById("header").className =
      "z-50 fixed table bg-blue w-full h-28 bg-opacity-0 duration-300 delay-100";
    document.getElementById("name").className =
      "text-white table-cell align-middle text-left pl-8 text-5xl w-[300px] duration-500";
    document.getElementById("logo").className =
      "table-cell align-middle pl-8 w-[100px] duration-500";
    document.getElementById("logoInside").className =
      "h-[100px] w-[100px] duration-500 mx-auto";
    document.getElementById("contacts").className =
      "text-white table-cell align-middle text-right py-[42px] text-lg float-right duration-500 ease-out";
  }

  window.addEventListener("scroll", function () {
    if (this.window.innerWidth > 1024) {
      var scrollPosition = window.scrollY;
      if (scrollPosition > 0) AfterScroll();
      else BeforeScroll();
    }
  });

  const [envActive, setEnvActive] = useState(false);
  const [phoneActive, setPhoneActive] = useState(false);

  function activate0() {
    setEnvActive(!envActive);
    setPhoneActive(false);
  }

  function activate1() {
    setPhoneActive(!phoneActive);
    setEnvActive(false);
  }

  return (
    <>
      {device !== "computer" ? (
        <header className="z-50 fixed">
          <div className="fixed z-50 bg-lighterBlue lg:bg-blue w-full bg-opacity-100 shadow h-12">
            <div className="relative h-full float-left">
              <a
                alt="Main page"
                aria-label="Return to the main page"
                className="float-left mx-4"
                href="/"
              >
                <img
                  src={Logo}
                  alt=""
                  className="mx-auto h-[48px] w-[48px]"
                ></img>
              </a>
            </div>
            <div className="relative h-full float-right text-white">
              <a
                aria-label="Contact Blur Motive here"
                alt="Contacts"
                href="/contacts"
                className="relative table h-12 float-right"
              >
                <div className="table-cell align-middle bg-blue">
                  <FontAwesomeIcon
                    icon={faComments}
                    className="relative scale-[2] float-right table-cell align-middle mx-4"
                  />
                </div>
              </a>
              <div
                onClick={activate0}
                className="relative table h-12 float-right"
              >
                <div className="table-cell align-middle bg-blue bg-opacity-70">
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    className="relative scale-[2] float-right table-cell align-middle mx-4"
                  />
                </div>
              </div>
              <div
                onClick={activate1}
                className="relative table h-12 float-right"
              >
                <div className="table-cell align-middle bg-blue bg-opacity-40">
                  <FontAwesomeIcon
                    icon={faPhone}
                    className="scale-[2] float-right table-cell align-middle mx-4"
                  />
                </div>
              </div>
            </div>
          </div>
          {envActive || phoneActive ? (
            envActive ? (
              <div className="text-white bg-black bg-opacity-30 w-full h-10 fixed top-12 text-center text-xl pt-[8px]">
                <a
                  aria-label="Write the official Blur Motive email"
                  alt="Email"
                  href="mailto:BlurMotive@gmail.com"
                  className="inline-block"
                >
                  BlurMotive@gmail.com
                </a>
                <div
                  onClick={activate0}
                  className="float-right text-xl pr-4 cursor-pointer"
                >
                  ✖
                </div>
              </div>
            ) : (
              <div className="text-white bg-black bg-opacity-30 w-full h-10 fixed top-12 text-center text-xl pt-[8px]">
                +37061621532
                <div
                  onClick={activate1}
                  className="float-right text-xl pr-4 cursor-pointer"
                >
                  ✖
                </div>
              </div>
            )
          ) : (
            <div className="fixed w-full top-0"></div>
          )}
        </header>
      ) : (
        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        <header
          id="header"
          className="z-50 fixed table bg-blue w-full h-28 bg-opacity-0 duration-1000"
        >
          <a
            id="logo"
            className="table-cell align-middle pl-8 w-[100px]"
            href="/"
          >
            <img
              src={Logo}
              alt=""
              id="logoInside"
              className="mx-auto h-[100px] w-[100px]"
            ></img>
          </a>

          <a
            aria-label="Return to main page here"
            alt="Main page"
            href="/"
            id="name"
            className="text-white table-cell align-middle text-left pl-8 text-5xl w-[300px]"
          >
            Blur Motive
          </a>

          <div
            className="h-full text-white flex flex-row items-center text-right text-lg float-right"
            id="contacts"
          >
            <span className="pr-12">
              <FontAwesomeIcon
                icon={faPhone}
                className="inline-block scale-110 mr-1"
              />
              <div className="inline-block font-light">+37061621532</div>
            </span>

            <span className="pr-6">
              <FontAwesomeIcon
                icon={faEnvelope}
                className="inline-block scale-110 mr-1"
              />
              <a
                aria-label="Write the official Blur Motive email"
                alt="Email"
                href="mailto:BlurMotive@gmail.com"
                className="inline-block font-light hover:underline"
              >
                BlurMotive@gmail.com
              </a>
            </span>
          </div>
        </header>
      )}
    </>
  );
}
