import { useContext, useEffect, useState, useRef } from "react";
import emailjs from "@emailjs/browser";

const Contacts = () => {
  const [success, setSuccess] = useState(null);
  const [errors, setErrors] = useState({});
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_gh6cqsc",
        "template_o5maafy",
        form.current,
        "x2llJX9v308FS7bC7"
      )
      .then(
        (result) => {
          setSuccess(true);
          window.scrollTo(0, 0);
        },
        (error) => {
          setSuccess(false);
          window.scrollTo(0, 0);
        }
      );

    const validateForm = (e) => {
      e.preventDefault();
      const formElements = form.current.elements;
      let formIsValid = true;
      let newErrors = {};

      for (let element of formElements) {
        if (element.type !== "submit" && element.value.trim() === "") {
          formIsValid = false;
          newErrors[element.name] = "This field is required";
        }
      }
    };
  };

  return (
    <div className="static text-center z-0 gradient-bg min-h-[250px]">
      <div className="relative w-full h-auto">
        {success === null ? (
          <div className="relative w-full top-32 z-10 text-6xl font-light text-white container mx-auto pb-8">
            <p className="mb-4">Contacts</p>
            <p className="mb-4 text-2xl">
              You're only one step away from rising above your competition.
            </p>
            <form
              ref={form}
              onSubmit={sendEmail}
              className="contact.html h-auto flex flex-col items-center"
              return
              false
            >
              <input
                type="text"
                name="user_name"
                placeholder="First Name"
                className="border-blue border text-blue mx-2 mb-1 text-left pl-2 w-4/5 lg:w-1/2 h-[50px] text-xl bg-white rounded-t"
                required
              ></input>
              <input
                type="text"
                name="user_email"
                placeholder="Email Adress"
                className="border-blue border text-blue mx-2 text-left pl-2 w-4/5 lg:w-1/2 h-[50px] text-xl bg-white rounded-b"
                required
              ></input>
              <input
                type="text"
                name="user_title"
                placeholder="Title"
                className="border-blue border text-blue mx-2 mt-4 mb-1 text-left pl-2 w-4/5 lg:w-1/2 h-[50px] text-xl bg-white rounded-t"
                required
              ></input>
              <textarea
                type="text"
                name="message"
                placeholder="Contents"
                className="border-blue border text-blue mx-2 text-left px-2 w-4/5 lg:w-1/2 h-[300px] text-xl bg-white rounded-b resize-none"
                required
              ></textarea>
              <input
                className="w-1/3 lg:w-1/6 text-white hover:brightness-90 bg-lighterBlue duration-200 text-xl font-normal mt-4 p-2 cursor-pointer rounded block mx-auto hover:shadow"
                type="submit"
                value="Submit"
              ></input>
            </form>
          </div>
        ) : (
          <>
            {success === true ? (
              <div className="relative w-full top-48 z-10 text-2xl font-light text-lighterBlue container mx-auto pb-8">
                <p className="mb-4">We got your contacts!</p>
              </div>
            ) : (
              <div className="relative w-full top-48 z-10 text-2xl font-light text-lighterBlue container mx-auto pb-8">
                <p className="mb-4">Something went wrong, try again later</p>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Contacts;
