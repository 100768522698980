import { Route, Routes } from "react-router-dom";

import "./App.css";

import { ContextProvider } from "./Context";

import Header from "./Header";
import Main from "./Main";
import Footer from "./Footer";
import Contacts from "./Contacts";

function App() {
  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };

  return (
    <div className="App">
      <ContextProvider>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Header />
                <Main />
                <Footer />
              </>
            }
          />
          <Route
            path="/contacts"
            element={
              <>
                <Header />
                <Contacts />
              </>
            }
          />
        </Routes>
      </ContextProvider>
    </div>
  );
}

export default App;
