import { React, useContext, useEffect, useState } from "react";

import MainContext from "../Context";

import Image from "../Images/marketing.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandshake } from "@fortawesome/free-solid-svg-icons";
import { faComment } from "@fortawesome/free-solid-svg-icons";
import { faFlagCheckered } from "@fortawesome/free-solid-svg-icons";

export default function Main() {
  const [data, setData] = useState({
    data: [
      {
        text: "Message",
        author: "- Write the author",
      },
    ],
  });

  function First() {
    document.getElementById("first").className =
      "z-10 absolute left-0 right-0 w-[900px] h-[400px] bg-blue mx-auto text-white table duration-[1000ms]";
    document.getElementById("second").className =
      "z-0 absolute left-[0px] right-[500px] w-[900px] h-[350px] bg-lighterBlue mt-[25px] mx-auto text-white table duration-[1000ms]";
    document.getElementById("third").className =
      "z-0 absolute left-[500px] right-0 w-[900px] h-[350px] bg-lighterBlue mt-[25px] mx-auto text-white table duration-[1000ms]";
  }

  function Second() {
    document.getElementById("third").className =
      "z-10 absolute left-0 right-0 w-[900px] h-[400px] bg-blue mx-auto text-white table duration-[1000ms]";
    document.getElementById("first").className =
      "z-0 absolute left-[0px] right-[500px] w-[900px] h-[350px] bg-lighterBlue mt-[25px] mx-auto text-white table duration-[1000ms]";
    document.getElementById("second").className =
      "z-0 absolute left-[500px] right-0 w-[900px] h-[350px] bg-lighterBlue mt-[25px] mx-auto text-white table duration-[1000ms]";
  }

  function Third() {
    document.getElementById("second").className =
      "z-10 absolute left-0 right-0 w-[900px] h-[400px] bg-blue mx-auto text-white table duration-[1000ms]";
    document.getElementById("third").className =
      "z-0 absolute left-[0px] right-[500px] w-[900px] h-[350px] bg-lighterBlue mt-[25px] mx-auto text-white table duration-[1000ms]";
    document.getElementById("first").className =
      "z-0 absolute left-[500px] right-0 w-[900px] h-[350px] bg-lighterBlue mt-[25px] mx-auto text-white table duration-[1000ms]";
  }

  const [position, setPosition] = useState(0);

  const [firstBox, setFirstBox] = useState(0);
  const [secondBox, setSecondBox] = useState(1);
  const [thirdBox, setThirdBox] = useState(2);

  const [firstBoxV, setFirstBoxV] = useState(0);
  const [secondBoxV, setSecondBoxV] = useState(1);
  const [thirdBoxV, setThirdBoxV] = useState(2);

  const { device } = useContext(MainContext);

  return (
    <>
      {device !== "computer" ? (
        <main className="static text-center z-0 inline-block w-full gradient-bg">
          {/*<div className="relative w-full h-[1080px]">
            <div className="relative w-full top-80 z-10 text-3xl font-light text-white container mx-auto animate-fade">
              <h1>Experience How The Right Words Are The Answer.</h1>
              <a
                alt="Contacts"
                href="/contacts"
                className="relative text-blue hover:brightness-90 bg-white duration-200 rounded text-xl p-2 top-[20px] font-normal select-none"
              >
                Contact Us Here
              </a>
            </div>
          </div>*/}
          <section className="relative w-full mt-[300px] mb-[350px] pb-3 text-3xl font-light text-white container mx-auto animate-fade select-none">
            <h1>Experience How The Right Words Are The Answer.</h1>
            <a
              alt="Contacts"
              href="/contacts"
              className="relative text-blue hover:brightness-90 bg-white rounded text-lg top-2 p-2 font-normal select-none hover:shadow duration-200"
            >
              Contact Us Here
            </a>
          </section>
          <section className="relative w-full z-20 flex flex-col mb-12 container mx-auto">
            <h2 className="font-thin text-white text-center text-5xl w-full mx-auto pb-4 tracking-widest">
              OUR VALUES
            </h2>
            <article className="w-[100%-4px] m-1 py-2 px-6 text-white bg-lighterBlue rounded z-10">
              <FontAwesomeIcon
                icon={faHandshake}
                className="h-12 my-[16px] drop-shadow"
              />
              <p className="px-6 py-2 font-bold">
                Your relationship with your audience is everything. If you’re
                unable to connect with them, you lose them. We will prevent the
                latter.
              </p>
            </article>
            <article className="w-[100%-4px] m-1 p-2 px-6 text-white bg-lighterBlue rounded z-10">
              <FontAwesomeIcon
                icon={faFlagCheckered}
                className="h-12 my-[16px] drop-shadow"
              />
              <p className="px-6 py-2 font-bold">
                You’re in a constant competition, a race that seems endless.
                We’re here to put you in first place.
              </p>
            </article>
            <article className="w-[100%-4px] m-1 p-2 px-6 z-10 text-white bg-lighterBlue rounded">
              <FontAwesomeIcon
                icon={faComment}
                className="h-12 my-[16px] drop-shadow"
              />
              <p className="px-6 py-2 font-bold">
                Words are THE MOST powerful force on Earth. Whoever impacts
                their audience the most - wins. Are you ready to win?
              </p>
            </article>
          </section>

          <section className="relative w-full text-blue mb-32 mt-32">
            <h2 className="font-thin text-blue text-center text-5xl border-b-2 w-full mx-auto pb-4 tracking-widest px-8">
              WHY US?
            </h2>
            <ul className="text-blue flex flex-col w-full mt-12 text-justify px-8 leading-[30px] list-disc">
              <li>Let me let you in on something.</li>
              <li className="leading-[25px]">
                Ever since I learned how even the{" "}
                <span className="italic">simplest</span> of words structured in
                one way <span className="font-bold">NOBODY</span> is talking
                about made me able to get through to anyone, I have had an
                epiphany.
              </li>
              <li className="italic">Words are the key to people.</li>
              <li>
                They are the influence that rules the world itself, not
                authority.
              </li>
              <li>
                So I tested my limits and discovered something truly scary.
              </li>
              <li>
                It was too easy, even the most stubborn of people would look
                down in defeat.
              </li>
              <li>It almost sounds villainous, but my my did it work.</li>
              <li>
                I decided I wanted to show people how{" "}
                <span className="font-bold">unbelievably</span> good this is.
              </li>
              <li>
                I have assembled a team who are now just as, if not even more
                capable than I.
              </li>
              <li>
                And we wish to use the exact same secret to see you and your
                business thrive.
              </li>
              <li>
                If you aren’t capable of taking on some serious numbers, you
                might as well just pass on this one.
              </li>
            </ul>
          </section>
          <section className="relative text-white px-8 align-middle text-left leading-[30px] w-full container mx-auto flex flex-col bg-blue mb-8 mt-24">
            <h2 className="relative font-thin text-white text-center text-5xl w-full mx-auto tracking-widest pt-2">
              ABOUT
            </h2>
            <p className="text-white my-2">
              My name is Agris and I am the founder of Blur Motive.
            </p>
            <p className=" text-white my-2">
              I fell in love with the art of words the moment I realised the
              world is controlled by it.
            </p>
            <p className=" text-white my-2">
              I remember asking for things or convincing people to do anything
              was always a <span className="underline">gamble</span>. I always
              feared being turned down. I always feared people switching up at
              the last second. I always feared things I couldn’t control…
            </p>
            <p className=" text-white my-2">
              Scratch that, I feared things I{" "}
              <span className="font-bold">WOULDN’T</span> control.
            </p>
            <p className=" text-white my-2">
              The ability to wield words is so empowering the second you
              experience how{" "}
              <span className="underline">lethally effective</span> it can be.
            </p>
            <p className=" text-white my-2">
              It’s like holding the key to any door in the world, and I’m sure
              you can imagine how fun that would be.
            </p>
            <p className=" text-white my-2">
              My team and I have engineered a handful of seriously powerful
              strategies and ways of connecting with people with the power of
              words, combining the best with the best to make
              <span className="font-bold"> THE</span> best.
            </p>
            <p className=" text-white my-2">
              We’d like to explore these with you and see you skyrocket past
              your competition and leave a{" "}
              <span className="font-bold">blazing mile gap.</span>
            </p>
            <p className=" text-white my-2">
              But the <span className="italic">only</span> thing holding you
              back right now are those few clicks to drop us an email.
            </p>
          </section>

          {data.data.length > 2 ? (
            <>
              <div className="w-full h-[500px] mb-[150px]">
                <div className="font-thin text-blue text-center text-3xl w-full mx-auto pb-4 tracking-widest">
                  WHAT OTHERS ARE SAYING
                </div>
                <div
                  className="relative hover:opacity-90 duration-500 cursor-pointer"
                  onClick={() => {
                    if (position === data.data.length - 1) setPosition(0);
                    else setPosition(position + 1);

                    console.log(position);
                  }}
                >
                  <div className="z-0 relative w-full h-[350px] bg-lighterBlue mt-[25px] mx-auto text-white table px-6">
                    <p className="text-lg px-6 table-cell align-middle">
                      {data.data[position].text}
                      <p className="text-lg px-4 text-right pt-4">
                        {data.data[position].author}
                      </p>
                    </p>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          <section className="my-12">
            <a
              alt="Contacts"
              href="/contacts"
              className="relative text-white hover:brightness-90 bg-lighterBlue rounded text-2xl p-2 font-normal select-none hover:shadow duration-200"
            >
              Contact Us Here
            </a>
          </section>
        </main>
      ) : (
        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        <main className="static text-center z-0 inline-block w-full gradient-bg">
          <section className="relative w-full mt-[300px] mb-[350px] text-6xl font-light text-white container mx-auto animate-fade select-none">
            <h1>Experience How The Right Words Are The Answer.</h1>
            <a
              alt="Contacts"
              href="/contacts"
              className="relative text-blue hover:brightness-90 bg-white rounded text-2xl p-2 font-normal select-none hover:shadow duration-200"
            >
              Contact Us Here
            </a>
          </section>
          <section className="relative w-full mb-24 text-lighterBlue container mx-auto">
            <h2 className="font-thin text-white pb-4 text-center text-5xl w-full mx-auto tracking-widest">
              OUR VALUES
            </h2>
            <article className="z-20 flex flex-row">
              <div className="w-1/3 h-64 table-cell text-white drop-shadow">
                <FontAwesomeIcon
                  icon={faHandshake}
                  className="relative h-16 inline-block drop-shadow"
                />

                <div className="flex items-center h-[172px]">
                  <p className="px-6 text-lg font-bold">
                    Your relationship with your audience is everything. If
                    you’re unable to connect with them, you lose them. We will
                    prevent the latter.
                  </p>
                </div>
              </div>
              <article className="w-1/3 h-64 table-cell text-white drop-shadow border-x">
                <FontAwesomeIcon
                  icon={faFlagCheckered}
                  className="relative h-16 inline-block drop-shadow"
                />
                <div className="flex items-center h-[172px]">
                  <p className="px-6 text-lg font-bold">
                    You’re in a constant competition, a race that seems endless.
                    We’re here to put you in first place.
                  </p>
                </div>
              </article>
              <article className="w-1/3 h-64 table-cell text-white drop-shadow">
                <FontAwesomeIcon
                  icon={faComment}
                  className="relative h-16 inline-block drop-shadow"
                />
                <div className="flex items-center h-[172px]">
                  <p className="px-6 text-lg font-bold">
                    Words are THE MOST powerful force on Earth. Whoever impacts
                    their audience the most - wins. Are you ready to win?
                  </p>
                </div>
              </article>
            </article>
          </section>

          <section className="relative w-full text-blue mb-12 container mx-auto">
            <h2 className="font-thin text-blue text-center text-5xl border-b-2 w-full mx-auto pb-4 tracking-widest">
              WHY US?
            </h2>
            <div className="relative flex flex-row">
              <img
                className="relative justify-center items-center w-1/3 p-16 object-contain"
                src={Image}
                alt=""
              ></img>
              <div className="w-2/3 h-auto mt-12 text-blue text-lg text-justify leading-[40px]">
                <p>Let me let you in on something.</p>
                <p className="leading-[25px]">
                  Ever since I learned how even the{" "}
                  <span className="italic">simplest</span> of words structured
                  in one way <span className="font-bold">NOBODY</span> is
                  talking about made me able to get through to anyone, I have
                  had an epiphany.
                </p>
                <p className="italic">Words are the key to people.</p>
                <p>
                  They are the influence that rules the world itself, not
                  authority.
                </p>
                <p>
                  So I tested my limits and discovered something truly scary.
                </p>
                <p>
                  It was too easy, even the most stubborn of people would look
                  down in defeat.
                </p>
                <p>It almost sounds villainous, but my my did it work.</p>
                <p>
                  I decided I wanted to show people how{" "}
                  <span className="font-bold">unbelievably</span> good this is.
                </p>
                <p>
                  I have assembled a team who are now just as, if not even more
                  capable than I.
                </p>
                <p>
                  And we wish to use the exact same secret to see you and your
                  business thrive.
                </p>
                <p>
                  If you aren’t capable of taking on some serious numbers, you
                  might as well just pass on this one.
                </p>
              </div>
            </div>
          </section>
          <section className="z-0 w-full relative text-white px-8 text-left text-lg leading-[30px] container mx-auto mt-24 pb-4 bg-blue rounded">
            <h2 className="relative text-center text-5xl w-full mx-auto tracking-widest pt-4">
              ABOUT
            </h2>
            <p className="p-2 my-2">
              My name is Agris and I am the founder of Blur Motive.
            </p>
            <p className="p-2 my-2">
              I fell in love with the art of words the moment I realised the
              world is controlled by it.
            </p>
            <p className="p-2 my-2">
              I remember asking for things or convincing people to do anything
              was always a <span className="underline">gamble</span>. I always
              feared being turned down. I always feared people switching up at
              the last second. I always feared things I couldn’t control…
            </p>
            <p className="p-2 my-2">
              Scratch that, I feared things I{" "}
              <span className="font-bold">WOULDN’T</span> control.
            </p>
            <p className="p-2 my-2">
              The ability to wield words is so empowering the second you
              experience how{" "}
              <span className="underline">lethally effective</span> it can be.
            </p>
            <p className="p-2 my-2">
              It’s like holding the key to any door in the world, and I’m sure
              you can imagine how fun that would be.
            </p>
            <p className="p-2 my-2">
              My team and I have engineered a handful of seriously powerful
              strategies and ways of connecting with people with the power of
              words, combining the best with the best to make
              <span className="font-bold"> THE</span> best.
            </p>
            <p className="p-2 my-2">
              We’d like to explore these with you and see you skyrocket past
              your competition and leave a{" "}
              <span className="font-bold">blazing mile gap.</span>
            </p>
            <p className="p-2 mt-2">
              But the <span className="italic">only</span> thing holding you
              back right now are those few clicks to drop us an email.
            </p>
          </section>

          {data.data.length > 2 ? (
            <>
              <div className="w-full h-[500px] mb-[150px]">
                <div className="font-thin text-blue text-center text-5xl w-full mx-auto pb-4 tracking-widest">
                  WHAT OTHERS ARE SAYING
                </div>
                <div
                  className="relative hover:opacity-90 duration-500 cursor-pointer w-full"
                  onClick={() => {
                    if (window.innerWidth > 1024) {
                      if (position === 0) {
                        First();
                        setPosition(1);

                        if (thirdBox === data.data.length - 1) setFirstBox(0);
                        else setFirstBox(thirdBox + 1);

                        setTimeout(() => {
                          if (thirdBoxV === data.data.length - 1)
                            setFirstBoxV(0);
                          else setFirstBoxV(thirdBoxV + 1);
                        }, 300);
                      } else if (position === 1) {
                        Second();
                        setPosition(2);

                        if (firstBox === data.data.length - 1) setSecondBox(0);
                        else setSecondBox(firstBox + 1);
                        setTimeout(() => {
                          if (firstBoxV === data.data.length - 1)
                            setSecondBoxV(0);
                          else setSecondBoxV(firstBoxV + 1);
                        }, 300);
                      } else if (position === 2) {
                        Third();
                        setPosition(0);

                        if (secondBox === data.data.length - 1) setThirdBox(0);
                        else setThirdBox(secondBox + 1);

                        setTimeout(() => {
                          if (secondBoxV === data.data.length - 1)
                            setThirdBoxV(0);
                          else setThirdBoxV(secondBoxV + 1);
                        }, 300);
                      }
                    }
                  }}
                >
                  <div
                    id="third"
                    className="z-0 absolute left-[0px] right-[500px] w-[900px] h-[350px] bg-lighterBlue mt-[25px] mx-auto text-white table duration-[1000ms]"
                  >
                    <p className="text-lg px-20 table-cell align-middle">
                      {data.data[firstBoxV].text}
                      <p className="text-lg px-4 text-right pt-4">
                        {data.data[firstBoxV].author}
                      </p>
                    </p>
                  </div>

                  <div
                    id="second"
                    className="z-10 absolute left-0 right-0 w-[900px] h-[400px] bg-blue mx-auto text-white table duration-[1000ms]"
                  >
                    <p className="text-lg px-20 table-cell align-middle">
                      {data.data[secondBoxV].text}
                      <p className="text-lg px-4 text-right pt-4">
                        {data.data[secondBoxV].author}
                      </p>
                    </p>
                  </div>

                  <div
                    id="first"
                    className="z-0 absolute left-[500px] right-0 w-[900px] h-[350px] bg-lighterBlue mt-[25px] mx-auto text-white table duration-[1000ms]"
                  >
                    <p className="text-lg px-20 table-cell align-middle">
                      {data.data[thirdBoxV].text}
                      <p className="text-lg px-4 text-right pt-4">
                        {data.data[thirdBoxV].author}
                      </p>
                    </p>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          <section className="my-12">
            <a
              alt="Contacts"
              href="/contacts"
              className="relative text-white hover:brightness-90 bg-lighterBlue rounded text-2xl p-2 font-normal select-none hover:shadow duration-200"
            >
              Contact Us Here
            </a>
          </section>
        </main>
      )}
    </>
  );
}
