export default function Footer() {
  return (
    <footer>
      <section className="bg-lighterBlue py-1 w-full text-lg font-light text-white text-center flex flex-col">
        <p className="selection:bg-blue">+37061621532</p>
        <p className="selection:bg-blue">BlurMotive@gmail.com</p>
      </section>
      <section
        id="footer"
        className="bg-blue py-1 w-full font-light text-white text-center table"
      >
        © 2024, Blur Motive
      </section>
    </footer>
  );
}
