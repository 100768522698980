import React, { useEffect, createContext, useState, useCallback } from "react";

const MainContext = createContext();

export function ContextProvider({ children }) {
  const [device, setDevice] = useState("computer");

  function logDevice() {
    if (window.innerWidth <= 1024) {
      setDevice("tablet");
    } else {
      setDevice("computer");
    }
  }

  useEffect(() => {
    logDevice();
  });

  useEffect(() => {
    window.addEventListener("resize", logDevice);

    return () => {
      window.removeEventListener("resize", logDevice);
    };
  });
  return (
    <MainContext.Provider
      value={{
        device,
      }}
    >
      {children}
    </MainContext.Provider>
  );
}

export default MainContext;
